import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { checkInData, uploadExcelFile, setCheckinDocs,setExcelData } from "../../actions/retrieveDataActions";
import JsonEditor from "../common/json/JsonEditor";
import ReactJson from "react-json-view";
import CheckinDataTags from "./CheckinDataTags";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddTaskIcon from '@mui/icons-material/AddTask';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import CheckinExcel  from "./CheckinExcel";
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import json_img from '../../assets/images/checkinImages/json_img.png'
import filter_gif from '../../assets/images/checkinImages/filter_gif.gif'

import Link from '@mui/material/Link';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const ImageTooltip = styled('img')({
  width: '620px',
  height: '238px',
});
const StyledChipInput = withStyles(() => ({
  root: {
      border: 'none',
  },
  input: {
      border: 'none !important',
  }
}))(ChipInput);

class CheckinDataSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: '',
      isValidated: 0,
      isMoreButtonClicked: false,
      checkinDocuments: [],
      files: "",
      fileUploaded: false,
      showTags: false,
      showFilter:false,
      tags: [],
      file: null,
      validJson:"empty",
    };
    (this.pipe = JSON.stringify({}, null, 2))
  }
  
  
  componentDidUpdate(prevProps) {
    if (this.props.excelData !== prevProps.excelData) {
      this.pipe = JSON.stringify(this.props.excelData);
      if(this.props.excelData !== undefined && this.props.excelData.length > 0)
      for(var i =0 ;i<this.props.excelData.length-1 ; i++)
      {  
        
        { let checkinDocExcel = JSON.stringify(this.props.excelData[i])
        this.setState((prevState) => ({
        isMoreButtonClicked: false,
        saved: JSON.stringify([...prevState.checkinDocuments, JSON.parse(checkinDocExcel) ]),
        isValidated: 1,
        tags: [],
        showTags : true
      }));
    }}
  }
  if(this.props.clearJSON !== prevProps.clearJSON)
  {
    if(this.props.clearJSON === true)
    {
      this.setState({
        checkinDocuments : []
      })
    }
  }
  this.props.setCheckinDocs(this.state.checkinDocuments);
} 




handleValidateJson = (pipe) => {
  let savedJson = ""
  let isValidated = 0;
  let fileUploaded = this.state.fileUploaded;
  try {
    if (!fileUploaded) {
      if (pipe !== "{}") { savedJson = pipe; isValidated = 1; }
      else {
        savedJson = this.state.saved
        isValidated = 1
        fileUploaded = false;
      }
    }
    else {
      savedJson = this.state.fileData;
      isValidated = 1;
      fileUploaded = false
    }
       this.setState({
        saved: savedJson,
        isValidated: isValidated,
        fileUploaded: fileUploaded,
        tags: [],
        showTags: true,
        showFilter:false,
        validJson:"valid"
      });
      return this.setState(JSON.parse(savedJson));
    }
    catch (e) {
      this.setState({
        saved: this.pipe,
        isValidated: 2,
        fileUploaded: false,
        tags: [],
        showTags: this.state.showTags,
        validJson:"invalid"
      });
    }
  }

  
  
  jsonBeautifier = () => {
    let jsonData = null;
    jsonData = JSON.stringify(this.handleValidateJson(this.state.saved), null, 2);
    this.setState({
      saved: jsonData,
      isValidated: 1
    });
  };

  handlesubmitTags = (checkinDataTagChips) => {
    this.setState({ tags: [...checkinDataTagChips] })
  }
  
  handleAddMore = () => 
  {
    
    if(this.props.excelData !== undefined && this.props.excelData.length > 0)
    {
     
      let excelDataUploaded = [...this.props.excelData]
      let excel = [];
      for(var i=0 ;i<excelDataUploaded.length-1 ;i++)
      {
        let exData = { "data": excelDataUploaded[i] ,"tags" : this.state.tags}
        excel.push(exData);
      }
      this.setState((prevState) =>({
        saved: "",
        checkinDocuments: [...prevState.checkinDocuments, ...excel],
        isMoreButtonClicked: false,
        isValidated: 0,
        tags: [],
        showTags : false,
        showFilter:true,
        validJson:"empty"
      }))


    }
    else
    {
      let rootData =  { "data": JSON.parse(this.state.saved) ,"tags" : this.state.tags}
      this.setState((prevState) =>({
        saved: "",
        checkinDocuments: [...prevState.checkinDocuments, rootData],
        isMoreButtonClicked: false,
        isValidated: 0,
        tags: [],
        showTags : false,
        showFilter:true,
        validJson:"empty"
      }))
    }
    this.pipe = JSON.stringify({}, null, 2);
    this.props.resetDisableNext();
    this.props.setExcelData({});
    if(this.props.clearJSON === true)
        this.props.setClearJson();
  }
 
  handleUpload = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      this.setState({
        fileData: e.target.result,
        saved: e.target.result,
        fileUploaded: true,
      });
      this.handleValidateJson()
    };
  };

   beautifyJSON=() =>{
     if(this.state.validJson==="valid"){
       try {
           const parsedData = JSON.parse(this.state.saved);
           const beautifiedData = JSON.stringify(parsedData, null, 4);
          this.setState({saved: beautifiedData});
       } catch (error) {
           return `Invalid JSON data: ${error.message}`;
       }
     }
  }
  
  updateValidJson=()=>{
    this.setState({validJson:"valid"});
    this.beautifyJSON()
  }

  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col-1" ></div>

          <div class="col-6" >
            <JsonEditor
              jsonTemplate={this.state.saved}
              pipe={(newPipe) => {
                this.pipe = newPipe;
                this.handleValidateJson(newPipe)
              }}
              jsonBeautifier={this.beautifyJSON}
            />
          </div>
          <div class="col-5" >
            <div class="row" style={{ marginLeft: "30px",marginTop: "20px"}}>
              <div class="col-12">
                <Tooltip title="Upload Json">
                  <Button
                    style={{ background: "#3f51b5", color: "#fff" }}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    name="json"
                    startIcon={<img src={json_img} alt="1" style={{ 'height': '26px', color: "#ff9a03", paddingRight: "15px" }}
                      id="json_img" />}
                  >
                    Upload JSON file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".json"
                      onChange={(e) => { this.handleUpload(e); e.target.value = "" }}

                      multiple
                    />
                  </Button>
                </Tooltip>
              </div>
              <div class="col-12" style={{ marginBottom: "25px",maxWidth:"386px"}}>
                <CheckinExcel resetDisableNext = {this.props.resetDisableNext} updateValidJson={this.updateValidJson}/>                  
              </div>
              {this.state.validJson === "valid" && this.state.showTags && <div class="col-12">
                <Box display="flex" alignItems="center">
                  <LocalOfferIcon sx={{ marginRight: 1, color: "#ff9a03" }} />
                  <Typography variant="h7"><b>Enter Data tags seperated by comma or space :</b></Typography>
                </Box> <br />
                <CheckinDataTags handlesubmitTags={this.handlesubmitTags} />
              </div>}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4" style={{ marginLeft: "105px" }}>
            <p>{this.state.validJson === "valid" ? <Alert severity="success" style={{width: "330px"}}>JSON is valid. Click ‘Add’ to proceed</Alert>
              : this.state.validJson === "invalid" ? <Alert severity="error" style={{width: "330px"}}>Invalid JSON. Please review and correct</Alert> : null}</p>
          </div>
          <div class="col-4" style={{ marginLeft: "285px" }}>
            {this.state.isValidated !== 1 ?
              <Tooltip title="Add Data to be Checked IN">
                <Button style={{ width: "125px", height: "45px", marginLeft: "-208px" }} endIcon={<AddTaskIcon />} color="primary" disabled variant="contained">ADD</Button>
              </Tooltip> :
              <Tooltip title="Add Data to be Checked IN">
                <Button className={"magnifyButton"} style={{ width: "125px", height: "45px", marginLeft: "-208px" }} endIcon={<AddTaskIcon />} color="primary" variant="contained" onClick={e => this.handleAddMore()}>ADD</Button>
              </Tooltip>
            }
          </div>
        </div> <br />
        <div class="row">
          <div class="col-1"></div>
          <div class="col-6 checkinDocsRow" style={{ overflow: "scroll", height: "400px", background: "#272822" }}>
            {this.state.checkinDocuments &&
              <ReactJson theme={"monokai"} src={this.state.checkinDocuments} onSelect={(selectedData) => this.props.handlesubmitFilters(selectedData)} shouldCollapse={(field)=>true}/>
            }
          </div>
          <div class="col-5">
            <Box display="flex" alignItems="center" style={{ marginTop: "15px", marginBottom: "5px", marginLeft: "35px" }}>
              <FilterAltRoundedIcon sx={{ marginRight: 1, color: "#dc4fad" }} />
              <Typography variant="h7"><b>Choose filters from the JSON list on the left.</b></Typography>
            </Box><br />

            {this.state.showFilter &&
              this.props.filters.length > 0 &&
              <StyledChipInput
                style={{ marginLeft: "35px" }}
                value={this.props.filters.map(chip => chip.name)}
                onDelete={(chip) => this.props.handleDeleteFilter(chip)}
                blurBehavior='add'
                fullWidth={true}
                onBeforeInput={(e) => {
                  e.preventDefault();
                }}
                allowDuplicates={false}
              />
            }
            {this.props.filters.length === 0 &&
              <div style={{ marginLeft: "100px" }}>
                <Tooltip
                  title={<ImageTooltip src={filter_gif} alt="Tooltip Image" />}
                  arrow
                >

                  <Link href="#" underline="hover">
                    How does it work?
                  </Link>
                </Tooltip>
              </div>}
          </div>
          <div class="col-2">
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkedOutData: state.retrieveData.checkedOutData,
    checkedInData: state.retrieveData.checkedInData,
    checkinStatus: state.retrieveData.checkinStatus,
    excelResponsemessage: state.retrieveData.excelResponsemessage,
    excelResponseCount: state.retrieveData.excelResponseCount,
    checkinErrorMsg: state.retrieveData.errorMsg,
    excelData : state.retrieveData.excelData,
    teamID: state.auth.team
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (path) => dispatch(setLocation(path)),
    checkInData: (data, id) => dispatch(checkInData(data, id)),
    uploadExcelFile: (data, id) => dispatch(uploadExcelFile(data, id)),
    setCheckinDocs: (data) => dispatch(setCheckinDocs(data)),
    setExcelData : (data) => dispatch(setExcelData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinDataSummary);